.button-animation button {
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.button-animation button:nth-child(1) {
  transition-delay: 1.2s;
  display: none;
}

/* .button-animation button:nth-child(2) {
  transition-delay: 0.2s;
  display: none;

} */

.button-animation button.show {
  opacity: 1;
  display: block;
}