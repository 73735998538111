
.circle-overlay {
  display: block;
  z-index: 9999;
  /* background: rgba(33, 33, 33, .6); */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.circle {
  width: 70px;
  text-align: center;
}

.circle-overlay__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.circle__bounce {
  width: 15px;
  height: 15px;
  background-color: #FFFFFF;
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out both;
    animation-delay: 0s;
  border: 2px solid black;  
}

.circle__bounce--1 {
  animation-delay: -0.32s;
}

.circle__bounce--2 {
  animation-delay: -0.16s;
}

@keyframes bouncedelay {
  0%, 80%, 100% {
      transform: scale(0);
  } 
  40% {
     transform: scale(1);
  }
}